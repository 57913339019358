'use client';

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';

interface ForgotPasswordProps {
  onBack: () => void;
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    if (!email || !email.includes('@')) {
      setError('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email.trim() }),
      });

      const data = await response.json();

      // Even if the email doesn't exist, we return a success response for security
      // So we always show the success message if the request was processed
      if (response.ok) {
        setSuccess(true);
      } else {
        throw new Error(data.error || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An unexpected error occurred.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Custom styles for the success screen
  const styles = {
    successIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '1.5rem',
    },
    customSuccessIcon: {
      width: '80px',
      height: '80px',
      filter: 'drop-shadow(0 4px 10px rgba(249, 168, 212, 0.5))',
      marginBottom: '0.5rem',
    }
  };

  return (
    <motion.div
      className="forgotPasswordContainer"
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.3 }}
    >
      {success ? (
        <div className="successContainer">
          <div className="successIcon" style={styles.successIconContainer}>
            <motion.div 
              style={styles.customSuccessIcon}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="38" fill="url(#gradient)" />
                <path d="M28 40L36 48L52 32" stroke="#1a1a1a" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
                <defs>
                  <linearGradient id="gradient" x1="0" y1="0" x2="80" y2="80" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor="#ffb88c" />
                    <stop offset="33%" stopColor="#ff9a9e" />
                    <stop offset="66%" stopColor="#fad0c4" />
                    <stop offset="100%" stopColor="#ffd1ff" />
                  </linearGradient>
                </defs>
              </svg>
            </motion.div>
          </div>
          <p className="successMessage">
            If an account exists with that email, we've sent password reset instructions.
          </p>
          <p className="successSubtext">
            Check your email (including spam folder) and follow the reset link.
          </p>
          <button onClick={onBack} className="backButton">
            Return to Login
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="forgotPasswordForm">
          <p className="formDescription">
            Forgot your password? Enter your email and we'll send you a reset link.
          </p>
          
          {error && <div className="errorMessage">{error}</div>}
          
          <div className="inputWrapper">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="emailInput"
              disabled={isSubmitting}
              required
            />
          </div>
          
          <div className="buttonGroup">
            <button
              type="submit"
              className="sendResetButton"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Reset Instructions'}
            </button>
            
            <button
              type="button"
              onClick={onBack}
              className="cancelButton"
              disabled={isSubmitting}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </motion.div>
  );
};

export default ForgotPassword; 