"use client";

import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import './SignUp.css'; // Import the new CSS file

interface LoobrarySignUpProps {
  onBack: () => void;
  onExplore: () => void;
}

const LoobrarySignUp: React.FC<LoobrarySignUpProps> = ({ onBack, onExplore }) => {
  const { login } = useAuth();
  const [currentPhase, setCurrentPhase] = useState(1); // Tracks current phase of the form
  const [formData, setFormData] = useState({
    pseudonym: '', // User's pseudonym
    password: '', // User's password
    email: '', // User's email
    phone: '', // User's phone number
  });
  const [error, setError] = useState<string>(''); // Stores error messages
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Tracks submission state
  const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false); // Tracks successful submission

  // Add effect for particle animation
  useEffect(() => {
    if (currentPhase === 3 && submissionSuccess) {
      // Set random X values for each particle
      const particles = document.querySelectorAll('.particle');
      particles.forEach(particle => {
        const randomX = (Math.random() * 100 - 50) + 'px';
        (particle as HTMLElement).style.setProperty('--random-x', randomX);
      });
    }
  }, [currentPhase, submissionSuccess]);

  // Validate Phase 1 Fields: Pseudonym and Password
  const validatePhase1 = () => {
    if (!formData.pseudonym) {
      setError('Pseudonym is required.');
      return false;
    }
    if (!formData.password || formData.password.length < 6) {
      setError('Password must be at least 6 characters.');
      return false;
    }
    return true;
  };

  // Validate Phase 2 Fields: Email (required) and Phone (optional)
  const validatePhase2 = () => {
    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      setError('A valid email is required.');
      return false;
    }
    return true;
  };

  // Handle Input Changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Navigate to Next Phase
  const handleNext = async () => {
    if (isSubmitting) return;
    
    if (currentPhase === 1 && validatePhase1()) {
      setError('');
      setCurrentPhase(2);
    } else if (currentPhase === 2 && validatePhase2()) {
      setError('');
      await handleSubmit();
    }
  };

  // Navigate to Previous Phase
  const handlePreviousPhase = () => {
    setError('');
    setCurrentPhase(currentPhase - 1);
  };

  // Handle Form Submission
  const handleSubmit = async () => {
    if (isSubmitting) return;
    setError('');
    setIsSubmitting(true);

    try {
      const payload = {
        pseudonym: formData.pseudonym.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone?.trim() || null,
        password: formData.password,
        dataType: 'userAccount',
        createdAt: new Date().toISOString(),
      };

      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        // Use the login function from useAuth instead of setting state directly
        const loginResult = await login({
          pseudonym: formData.pseudonym,
          password: formData.password,
          isAnonymous: false
        });
        
        if (loginResult.success) {
          setSubmissionSuccess(true);
          setCurrentPhase(3);
        } else {
          // Login failed after successful signup
          setError('Account created but login failed. Please try logging in manually.');
          setIsSubmitting(false);
        }
      } else {
        // Handle specific error cases
        if (data.code === 'duplicate_email') {
          setError('This email is already registered. Please use a different email.');
        } else if (data.code === 'duplicate_pseudonym') {
          setError('This pseudonym is already taken. Please choose another one.');
        } else if (data.code === 'missing_fields') {
          setError(`Missing required fields: ${data.missingFields.join(', ')}`);
        } else if (data.code === 'invalid_email') {
          setError('Please enter a valid email address.');
        } else {
          setError(data.error || 'An error occurred during signup.');
        }
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      setError('An unexpected error occurred. Please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="signupScreen">
      {/* Form Container with integrated back button in header */}
      <div className="formContainer">
        <div className="formHeader">
          <h2 className="title">
            {currentPhase === 1 ? "Create a Loob Account" : 
             currentPhase === 2 ? "Contact Info" : 
             submissionSuccess ? /* "Account Created!" */ "" : ""}
          </h2>
          <button 
            type="button" 
            className="backButton" 
            onClick={onBack}
            aria-label="Go back to login screen"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>

        {currentPhase === 1 && (
          <form>
            <input
              type="text"
              name="pseudonym"
              placeholder="Enter a pseudonym"
              aria-label="Pseudonym"
              value={formData.pseudonym}
              onChange={handleInputChange}
              required
              className="inputField"
            />
            <input
              type="password"
              name="password"
              placeholder="Enter a password"
              aria-label="Password"
              value={formData.password}
              onChange={handleInputChange}
              required
              className="inputField"
            />
            {error && <p className="error">{error}</p>}
            <button type="button" onClick={handleNext} className="actionButton">
              Next
            </button>
          </form>
        )}
        
        {currentPhase === 2 && (
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              type="email"
              name="email"
              placeholder="Enter an email"
              aria-label="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
              disabled={isSubmitting}
              className="inputField"
            />
            <input
              type="tel"
              name="phone"
              placeholder="Enter your phone number (optional)"
              aria-label="Phone"
              value={formData.phone}
              onChange={handleInputChange}
              disabled={isSubmitting}
              className="inputField"
            />
            {error && <p className="error">{error}</p>}
            <div className="buttonGroup">
              <button
                type="button"
                onClick={handlePreviousPhase}
                disabled={isSubmitting}
                className="actionButton secondary"
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleNext}
                disabled={isSubmitting}
                className="actionButton"
              >
                {isSubmitting ? 'Creating Account...' : 'Submit'}
              </button>
            </div>
          </form>
        )}
        
        {currentPhase === 3 && submissionSuccess && (
          <div className="finalPhase">
            <div className="celebration-particles">
              {[...Array(20)].map((_, i) => (
                <div 
                  key={i}
                  className="particle"
                  style={{
                    left: `${Math.random() * 100}%`,
                    top: `${Math.random() * 100}%`,
                    animationDuration: `${Math.random() * 3 + 2}s`,
                    animationDelay: `${Math.random() * 0.5}s`,
                    backgroundColor: `hsl(${Math.random() * 60 + 330}, 100%, 70%)`,
                    width: `${Math.random() * 10 + 5}px`,
                    height: `${Math.random() * 10 + 5}px`,
                  }}
                />
              ))}
            </div>
            <h2 className="mainTitle">Account Created!</h2>
            <p className="description">
              Welcome to Loob! Your account has been successfully created.
            </p>
            <p className="nextSteps">
              Next, you'll choose a companion to enhance your experience and guide you through the app.
            </p>
            <button onClick={onExplore} className="actionButton">
              Choose Your Companion
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoobrarySignUp;
