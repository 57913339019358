"use client";

import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../hooks/useAuth";
import ForgotPassword from './ForgotPassword';
import "./LoginScreen.css";
import "./SplashScreen.css";
import Image from 'next/image';
import { SignUpScreen } from './SignUpScreen';

// Counter for debug purposes
let instanceCounter = 0;

export const LoginScreen: React.FC<{ 
  onComplete?: (username: string, password: string) => void,
  isProcessing?: boolean
}> = ({ onComplete, isProcessing }) => {
  // Create a unique instance ID for debugging
  const instanceId = useRef(`login-${++instanceCounter}`);
  console.log(`[LoginScreen ${instanceId.current}] Initializing`);
  
  const { login, error: loginError, isLoading } = useAuth();
  
  // State management
  const [mounted, setMounted] = useState(false);
  const [phase, setPhase] = useState<"loginPhase" | "forgotPasswordPhase">("loginPhase");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isProcessingLogin, setIsProcessingLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const [loginStartTime, setLoginStartTime] = useState<number | null>(null);

  // Initial setup
  useEffect(() => {
    console.log(`[LoginScreen ${instanceId.current}] Initial setup, phase: ${phase}`);
    setMounted(true);
  }, [phase]);

  // Safety timeout to prevent infinite login processing
  useEffect(() => {
    if ((isProcessingLogin || isProcessing) && loginStartTime) {
      const timeoutId = setTimeout(() => {
        // If login has been processing for more than 10 seconds, reset the state
        if (Date.now() - loginStartTime > 10000) {
          console.warn('Login process timed out after 10 seconds');
          setIsProcessingLogin(false); // Only reset our local state
          setLocalError('Login timed out. Please try again.');
        }
      }, 10000);
      
      return () => clearTimeout(timeoutId);
    }
  }, [isProcessingLogin, isProcessing, loginStartTime]);

  const handleLogin = async () => {
    console.log(`[LoginScreen ${instanceId.current}] Attempting login for: ${username}`);
    
    // Don't proceed if already loading or in transition
    if (isLoading || isProcessingLogin || isProcessing) {
      console.log(`[LoginScreen ${instanceId.current}] Already processing, skipping`);
      return;
    }
    
    if (!username || !password) {
      setLocalError("Please enter both username and password");
      return;
    }
    
    setIsProcessingLogin(true);
    setLoginStartTime(Date.now());
    setLocalError(null);
    
    try {
      // If onComplete is provided, use it directly
      if (onComplete) {
        console.log(`[LoginScreen ${instanceId.current}] Using provided onComplete callback`);
        onComplete(username, password);
        // Don't reset isProcessingLogin - let the parent component manage this
        return;
      }

      // Otherwise use the useAuth hook
      console.log(`[LoginScreen ${instanceId.current}] Using local login hook`);
      const result = await login({
        pseudonym: username,
        password: password,
        isAnonymous: false
      });
      
      if (!result.success) {
        console.log(`[LoginScreen ${instanceId.current}] Login failed:`, result);
        setIsProcessingLogin(false);
        setLocalError("Login failed. Please check your credentials.");
        return;
      }
      
      console.log(`[LoginScreen ${instanceId.current}] Login successful`);
      setIsProcessingLogin(false);
    } catch (error) {
      console.error(`[LoginScreen ${instanceId.current}] Login error:`, error);
      setLocalError("An error occurred during login. Please try again.");
      setIsProcessingLogin(false);
    }
  };

  const handleStayAnonymous = () => {
    console.log(`[LoginScreen ${instanceId.current}] Anonymous login temporarily disabled`);
  };

  const handleSignUp = () => {
    if (isProcessing || isProcessingLogin) return;
    
    console.log(`[LoginScreen ${instanceId.current}] Switching to sign-up view`);
    setShowSignUp(true);
  };

  // Early return if not mounted
  if (!mounted) {
    return null;
  }

  if (showSignUp) {
    return (
      <SignUpScreen 
        onClose={() => {
          setShowSignUp(false);
        }}
        onComplete={onComplete}
      />
    );
  }

  const headerContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
  };

  return (
    <div 
      className="loginScreen splashScreen"
      data-testid="motion-loginScreen"
      id={instanceId.current}
    >
      <div className="gridBackground" />
      
      <div className="splashScreenContent">
        <div style={headerContainerStyle}>
          <div className="logoTitleContainer" style={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            gap: '0.35rem', 
            marginBottom: '0.5rem' 
          }}>
            <Image 
              src="/LoobLogo.png"
              alt="Loob Logo"
              width={72}
              height={72}
              priority={true}
              style={{ display: 'block', transform: 'translateY(2px)' }}
            />
            <h1 className="splashTitle" style={{ 
              margin: 0, 
              fontSize: '2.7rem', 
              lineHeight: 1,
              fontWeight: 600,
              letterSpacing: '0.5px',
              paddingLeft: '0.1rem',
              transform: 'translateY(1px)', 
              background: 'linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff)',
              backgroundSize: '400% 400%',
              animation: 'gradient-shift 15s ease infinite',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              color: '#ff9a9e' // Fallback color
            }}>Loob</h1>
          </div>
          <p className="tagline" style={{ 
            margin: 0, 
            fontSize: '0.95rem', 
            opacity: 0.85, 
            textAlign: 'center',
            letterSpacing: '0.7px',
            fontWeight: 400,
            transform: 'translateY(-3px)'
          }}>
            frictionless burns
          </p>
        </div>

        {/* Developer Tools Section - Positioned at top right for desktop only */}
        <div className="devToolsCorner">
          <div className="devToolsLinks">
            <a 
              href="/rag-evaluation" 
              target="_blank" 
              rel="noopener noreferrer"
              className="devToolLink"
            >
              RAG Evaluation Tool
            </a>
            <a 
              href="/api/rag-diagnostic?query=camps+with+yoga" 
              target="_blank" 
              rel="noopener noreferrer"
              className="devToolLink"
            >
              RAG Diagnostic
            </a>
          </div>
        </div>

        {phase === "loginPhase" && (
          <div 
            className="inputContainer"
            style={{ maxWidth: '320px', width: '100%' }}
          >
            <input
              type="text"
              placeholder="Enter your pseudonym"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="pseudonymInput"
              disabled={isLoading || isProcessingLogin || isProcessing}
              style={{ marginBottom: '0.85rem' }}
            />
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="pseudonymInput"
              disabled={isLoading || isProcessingLogin || isProcessing}
              style={{ marginBottom: '1.25rem' }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            {(localError || loginError) && (
              <p className="error" style={{ marginBottom: '0.85rem', fontSize: '0.85rem', color: '#f87171' }}>
                {localError || loginError}
              </p>
            )}
            <button
              className="actionButton"
              onClick={handleLogin}
              disabled={isLoading || isProcessingLogin || isProcessing}
              style={{ marginBottom: '0.85rem' }}
            >
              {isLoading || isProcessingLogin || isProcessing ? "Logging in..." : "Log In"}
            </button>
            <button
              className="actionButton secondary"
              onClick={handleStayAnonymous}
              disabled={isLoading || isProcessingLogin || isProcessing}
              style={{ marginBottom: '1.75rem' }}
            >
              Stay Anonymous
            </button>
            <p className="createAccountText" style={{ 
              margin: 0, 
              fontSize: '0.85rem', 
              opacity: 0.9,
              letterSpacing: '0.3px',
              textAlign: 'center'
            }}>
              <span className="linkText" onClick={handleSignUp}>
                Sign up
              </span> or <span className="linkText" onClick={() => setPhase("forgotPasswordPhase")}>
                Forgot password?
              </span>
            </p>
          </div>
        )}

        {phase === "forgotPasswordPhase" && (
          <div
            style={{ width: '100%' }}
          >
            <ForgotPassword
              onBack={() => setPhase("loginPhase")}
            />
          </div>
        )}
      </div>
    </div>
  );
}; 