"use client";

import React, { createContext, useContext, useState, useEffect, ReactNode, useRef, useCallback } from "react";
import type { ViewType } from './HomePage';

export interface Companion {
  id: string;
  name: string;
  type?: string;
  description?: string;
  systemPrompt?: string;
  icon?: string;
  isBaseCompanion?: boolean;
  contextPath?: string;
}

export type Loobricate = {
  id: string;
  name: string;
  description: string;
  address: string;
  adminUsername: string;
  tags: string[];
  type: 'community' | 'venue' | 'gear' | 'talent';
  members: string[];
  admins: string[];
  email?: string;
  location?: { lat: number; lng: number };
  role?: 'admin' | 'member';
  profilePhoto?: string;
  profilePhotoPath?: string;
  coverPhoto?: string;
  coverPhotoPath?: string;
  photoUrls?: string[];
  isLoobricate?: boolean;
  dataType: 'loobricate' | 'userEntry';
  offeringType?: string;
};

export interface Entry {
  id: string;
  title: string;
  description: string;
  offeringType: string;
  photos: string[];
  createdAt: string;
  status: 'available' | 'unavailable' | 'maintenance';
  loobricateId: string;
  createdBy: string;
  creatorPseudonym: string;
  location?: string | { lat: number; lon: number };
}

export interface User {
  userId: string;
  pseudonym: string;
  email: string;
  phone: string;
  isAnonymous: boolean;
  connectedLoobricates: (Loobricate | string)[];
  activeCompanion: string | null;
  activeLoobricate: Loobricate | null;
  hasChosenCompanion: boolean;
  discoveredCompanions: string[];
  omiUid?: string;
}

export interface GlobalState extends User {
  sessionId: string | null;
  isRecording: boolean;
  setSessionId: (id: string | null) => void;
  setUserState: (state: Partial<User>) => void;
  clearUserState: () => void;
  setActiveLoobricate: (loobricate: Loobricate | null) => void;
  setConnectedLoobricates: (loobricates: Loobricate[]) => void;
  setActiveCompanion: (companion: Companion | null) => void;
  view: ViewType;
  setView: (view: ViewType) => void;
  setEditingEntry: (entry: Entry | null) => void;
  setIsRecording: (isRecording: boolean) => void;
  setIsProcessing: (isProcessing: boolean) => void;
  setTranscription: (transcription: string | null) => void;
  user: User;
  setUser: (user: User) => void;
  manualInit: () => void;
}

export const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

const ANONYMOUS_PREFIX = 'anon-';

const fetchConnectedLoobricates = async (userId: string | null): Promise<Loobricate[]> => {
  try {
    if (!userId) {
      // For anonymous users, fetch public loobricates
      console.log('Fetching public loobricates for anonymous user');
      try {
        const response = await fetch('/api/loobricates');
        if (!response.ok) {
          const errorText = await response.text().catch(() => '');
          console.error(`Failed to fetch loobricates: ${response.status} ${response.statusText}, ${errorText}`);
          return []; // Return empty array rather than throwing
        }
        const data = await response.json();
        
        if (!Array.isArray(data)) {
          console.error('Expected array of loobricates but got:', typeof data);
          return [];
        }

        return data
          .filter((l: any) => l.dataType === 'loobricate')
          .map((l: any) => ({
            id: l.id,
            name: l.name || 'Unnamed Loobricate',
            description: l.description || '',
            address: l.addressLine1 ? `${l.addressLine1}, ${l.city || 'Berlin'}` : '',
            adminUsername: l.adminUsername || '',
            tags: l.tags || [],
            type: l.type || 'community',
            members: l.members || [],
            admins: l.admins || [],
            email: l.email,
            location: l.location,
            dataType: 'loobricate',
            profilePhoto: l.profilePhoto,
            profilePhotoPath: l.profilePhotoPath,
            coverPhoto: l.coverPhoto,
            coverPhotoPath: l.coverPhotoPath,
            isLoobricate: true
          }));
      } catch (error) {
        console.error('Error fetching public loobricates:', error);
        return []; // Return empty array on error
      }
    }

    // Logged-in users get connected loobricates
    console.log(`Fetching user data for userId: ${userId}`);
    
    // Add retries for API calls
    let retries = 0;
    const maxRetries = 3;
    
    while (retries < maxRetries) {
      try {
        const response = await fetch(`/api/users/${userId}/data`);
        
        // Check for various error conditions and provide helpful messages
        if (!response.ok) {
          const errorText = await response.text().catch(() => '');
          console.error(`User data API response error (${response.status}): ${errorText || response.statusText}`);
          
          if (response.status === 404) {
            console.warn(`User not found. User ID ${userId} may not exist in the database`);
            return []; // Return empty array for non-existent user
          } else if (response.status === 401 || response.status === 403) {
            console.warn('Authentication error. User may need to sign in again');
            throw new Error('Authentication error. Please sign in again');
          } else {
            if (retries < maxRetries - 1) {
              retries++;
              console.log(`Retrying fetch (attempt ${retries} of ${maxRetries - 1})...`);
              // Exponential backoff
              await new Promise(resolve => setTimeout(resolve, retries * 1000));
              continue; // Try again
            } else {
              throw new Error(`Failed to fetch user data: ${response.statusText || response.status}`);
            }
          }
        }
        
        const data = await response.json();
        
        if (!data.connectedLoobricates || !Array.isArray(data.connectedLoobricates)) {
          console.warn('Expected array of connected loobricates but got:', typeof data.connectedLoobricates);
          return [];
        }
        
        return data.connectedLoobricates
          .filter((l: any) => l.dataType === 'loobricate')
          .map((l: any) => ({
            id: l.id,
            name: l.name || 'Unnamed Loobricate',
            description: l.description || '',
            address: l.addressLine1 ? `${l.addressLine1}, ${l.city || 'Berlin'}` : '',
            adminUsername: l.adminUsername || '',
            tags: l.tags || [],
            type: l.type || 'community',
            members: l.members || [],
            admins: l.admins || [],
            email: l.email,
            location: l.location,
            dataType: 'loobricate',
            profilePhoto: l.profilePhoto,
            profilePhotoPath: l.profilePhotoPath,
            coverPhoto: l.coverPhoto,
            coverPhotoPath: l.coverPhotoPath,
            isLoobricate: true,
            role: l.admins?.includes(userId) ? 'admin' : 'member'
          }));
      } catch (apiError) {
        if (retries < maxRetries - 1) {
          retries++;
          console.log(`Retrying after error (attempt ${retries} of ${maxRetries - 1})...`);
          // Exponential backoff
          await new Promise(resolve => setTimeout(resolve, retries * 1000));
          continue; // Try again
        }
        console.error('Error fetching user data from API:', apiError);
        throw apiError; // Re-throw on final attempt
      }
    }
    
    // Should never reach here due to return or throw in the loop
    return [];
  } catch (error) {
    console.error('Error in fetchConnectedLoobricates:', error);
    
    // Provide a fallback to handle errors gracefully
    console.log('Returning empty loobricates array due to error');
    return [];
  }
};

export const GlobalStateProvider: React.FC<{ 
  children: ReactNode;
  initialState?: Partial<GlobalState>;
}> = ({ children, initialState }) => {
  const [sessionId, setSessionIdState] = useState<string | null>(initialState?.sessionId ?? null);
  const [isInitialized, setIsInitialized] = useState(true); // Start as already initialized
  const [isLoading, setIsLoading] = useState(false); // Start as not loading
  const [isRecording, setIsRecordingState] = useState(initialState?.isRecording ?? false);
  const [userState, setUserStateData] = useState<User>({
    userId: initialState?.userId ?? '',
    pseudonym: initialState?.pseudonym ?? '',
    email: initialState?.email ?? '',
    phone: initialState?.phone ?? '',
    isAnonymous: initialState?.isAnonymous ?? true,
    connectedLoobricates: initialState?.connectedLoobricates ?? [],
    activeCompanion: initialState?.activeCompanion ?? null,
    activeLoobricate: initialState?.activeLoobricate ?? null,
    hasChosenCompanion: initialState?.hasChosenCompanion ?? false,
    discoveredCompanions: initialState?.discoveredCompanions ?? [],
    omiUid: initialState?.omiUid ?? undefined,
  });
  const [currentView, setCurrentView] = useState<ViewType>(initialState?.view ?? 'Profile');
  const lastProcessedStateRef = useRef<string>('');
  const isDev = process.env.NODE_ENV === 'development';
  const initializationAttemptedRef = useRef(false);

  // Create a ref outside of useEffect
  const prevStateRef = useRef<string | null>(null);

  // Move all state initialization to a separate function that can be called later
  // after the app has already rendered
  const initializeStateFromStorage = useCallback(() => {
    if (initializationAttemptedRef.current) return;
    initializationAttemptedRef.current = true;
    
    if (isDev) {
      console.log('[GlobalStateContext] Lazy initialization triggered');
    }
    
    try {
      // Only attempt localStorage access within try/catch
      const storedState = localStorage.getItem('userState');
      const isLoggedIn = localStorage.getItem('isLoggedIn');
      
      if (storedState && isLoggedIn === 'true') {
        try {
          const parsedState = JSON.parse(storedState) as Partial<User>;
          if (parsedState?.userId) {
            if (isDev) {
              console.log('[GlobalStateContext] Setting state from localStorage:', {
                userId: parsedState.userId,
                isAnonymous: false
              });
            }
            
            setUserStateData(prev => ({
              ...prev,
              ...parsedState as User,
              isAnonymous: false
            }));
          }
        } catch (e) {
          console.error('[GlobalStateContext] Failed to parse stored state:', e);
        }
      } else {
        // If not logged in, set anonymous state explicitly
        setUserStateData(prev => ({
          ...prev,
          isAnonymous: true
        }));
      }
    } catch (error) {
      console.error('[GlobalStateContext] Error accessing localStorage:', error);
    }
  }, [isDev]);

  // Expose the initialization function to components
  const manualInit = useCallback(() => {
    if (!initializationAttemptedRef.current) {
      initializeStateFromStorage();
    }
  }, [initializeStateFromStorage]);

  // If there's initialState, use it immediately
  useEffect(() => {
    if (initialState && !initializationAttemptedRef.current) {
      initializationAttemptedRef.current = true;
      if (isDev) {
        console.log('[GlobalStateContext] Using provided initialState');
      }
    }
  }, [initialState, isDev]);

  // Drastically simplify the persistence effect
  useEffect(() => {
    if (userState.userId && !userState.userId.startsWith(ANONYMOUS_PREFIX) && !userState.isAnonymous) {
      try {
        const newStateJSON = JSON.stringify(userState);
        
        // Only save if the state has actually changed
        if (lastProcessedStateRef.current !== newStateJSON) {
          if (isDev) {
            console.log('Persisting user state to localStorage:', {
              userId: userState.userId,
              isAnonymous: userState.isAnonymous
            });
          }
          
          localStorage.setItem('userState', newStateJSON);
          localStorage.setItem('isLoggedIn', 'true');
          lastProcessedStateRef.current = newStateJSON;
        }
      } catch (error) {
        console.error('Error persisting state:', error);
      }
    }
  }, [userState, isDev]);

  const setSessionId = (id: string | null) => {
    setSessionIdState(id);
    try {
      if (id) {
        localStorage.setItem('sessionId', id);
      } else {
        localStorage.removeItem('sessionId');
      }
    } catch (error) {
      console.error('Error updating sessionId in localStorage:', error);
    }
  };

  const setUserState = (newState: Partial<User>) => {
    if (isDev) {
      console.log('[GlobalStateContext] Setting new user state:', {
        userId: newState.userId,
        isAnonymous: newState.isAnonymous
      });
    }
    
    setUserStateData(prev => {
      // Create updated state with all fields
      const updated = { 
        ...prev, 
        ...newState,
        // Ensure all required fields are present
        connectedLoobricates: newState.connectedLoobricates || prev.connectedLoobricates || [],
        discoveredCompanions: newState.discoveredCompanions || prev.discoveredCompanions || [],
        activeCompanion: newState.activeCompanion || prev.activeCompanion || null,
        hasChosenCompanion: newState.hasChosenCompanion ?? prev.hasChosenCompanion ?? false
      };
      
      // Handle isAnonymous flag properly
      if (newState.isAnonymous !== undefined) {
        updated.isAnonymous = newState.isAnonymous;
      } else if (newState.userId && newState.userId.length > 0) {
        updated.isAnonymous = false;
      }
      
      return updated;
    });
  };

  const clearUserState = () => {
    try {
      localStorage.removeItem('userState');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('sessionId');
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
    
    setUserStateData({
      userId: '',
      pseudonym: '',
      email: '',
      phone: '',
      isAnonymous: true,
      connectedLoobricates: [],
      activeCompanion: null,
      activeLoobricate: null,
      hasChosenCompanion: false,
      discoveredCompanions: [],
      omiUid: undefined,
    });
    
    if (isDev) {
      console.log('User state cleared');
    }
  };

  const setActiveLoobricate = (loobricate: Loobricate | null) => {
    setUserStateData(prev => ({
      ...prev,
      activeLoobricate: loobricate
    }));
  };

  const setConnectedLoobricates = (loobricates: Loobricate[]) => {
    setUserStateData(prev => ({
      ...prev,
      connectedLoobricates: loobricates
    }));
  };

  const setActiveCompanion = async (companion: Companion | null) => {
    // If the companion is null, just update the state
    if (!companion) {
      setUserStateData(prev => ({
        ...prev,
        activeCompanion: null,
        hasChosenCompanion: false
      }));
      return;
    }

    // Store the ID in the state to match the expected User type
    setUserStateData(prev => ({
      ...prev,
      activeCompanion: companion.id, // Store the ID to match User type
      hasChosenCompanion: true
    }));

    // If the user is logged in, update the preference in the backend
    if (userState.userId && !userState.userId.startsWith(ANONYMOUS_PREFIX) && !userState.isAnonymous) {
      // Prepare update data
      const updateData = {
        activeCompanion: companion, // Send the full companion object
        discoveredCompanions: [...(userState.discoveredCompanions || []), companion.id],
        hasChosenCompanion: true
      };
      
      // Implement retry logic for API calls
      const maxRetries = 2;
      let retryCount = 0;
      let success = false;
      
      while (!success && retryCount <= maxRetries) {
        try {
          // Add delay between retries
          if (retryCount > 0) {
            console.log(`GlobalStateContext: Retrying companion update (attempt ${retryCount}/${maxRetries})...`);
            await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
          }
          
          // Use the companions API endpoint
          const response = await fetch(`/api/users/${userState.userId}/companions`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateData)
          });

          if (!response.ok) {
            const errorText = await response.text();
            console.error('Failed to update companion preference:', response.status, errorText);
            
            // On the last retry, throw the error to be caught
            if (retryCount === maxRetries) {
              throw new Error(errorText);
            }
          } else {
            // Success - break out of retry loop
            success = true;
            console.log('Successfully updated companion preference');
          }
        } catch (error) {
          console.error('Error updating companion preference:', error);
          
          // On the last retry, throw to be caught by the outer try/catch
          if (retryCount === maxRetries) {
            throw error;
          }
        }
        
        retryCount++;
      }
      
      // If we failed to update after all retries, log a warning but continue
      if (!success) {
        console.warn('Failed to update companion preference after all retries. Continuing with local state only.');
      }
    }
  };

  const handleViewChange = (newView: ViewType) => {
    setCurrentView(newView);
  };

  const setEditingEntry = (entry: Entry | null) => {
    setUserStateData(prev => ({
      ...prev,
      editingEntry: entry
    }));
  };

  const setIsRecording = (isRecording: boolean) => {
    setIsRecordingState(isRecording);
  };

  const setIsProcessing = (isProcessing: boolean) => {
    setUserStateData(prev => ({
      ...prev,
      isProcessing: isProcessing
    }));
  };

  const setTranscription = (transcription: string | null) => {
    setUserStateData(prev => ({
      ...prev,
      transcription: transcription
    }));
  };

  const value = {
    ...userState,
    sessionId,
    isRecording,
    setIsRecording,
    setSessionId,
    setUserState,
    clearUserState,
    setActiveLoobricate,
    setConnectedLoobricates,
    setActiveCompanion,
    view: currentView,
    setView: handleViewChange,
    setEditingEntry,
    setIsProcessing,
    setTranscription,
    user: userState,
    setUser: setUserState,
    // Add the manual init function
    manualInit
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = (): GlobalState => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};
