'use client';

import React, { useState, useEffect } from 'react';
import { useSearchParams, useRouter } from 'next/navigation';

interface OmiBrowserLinkProps {
  onSuccess?: () => void;
}

const STORED_OMI_UID_KEY = 'pendingOmiUid';

export const OmiBrowserLink = ({ onSuccess }: OmiBrowserLinkProps) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const urlOmiUid = searchParams.get('uid');
  const [username, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState<'username' | 'verification'>('username');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);
  const [linkSuccess, setLinkSuccess] = useState(false);
  const [alreadyLinked, setAlreadyLinked] = useState(false);
  const [hasExistingLink, setHasExistingLink] = useState(false);

  // Store UID when it's available in URL
  useEffect(() => {
    if (urlOmiUid) {
      console.log('Found UID in URL:', urlOmiUid);
      localStorage.setItem(STORED_OMI_UID_KEY, urlOmiUid);
    } else {
      console.log('No UID found in URL params:', Object.fromEntries(searchParams.entries()));
    }
  }, [urlOmiUid, searchParams]);

  // Get the effective UID to use (from URL or localStorage)
  const getEffectiveUid = () => {
    if (urlOmiUid) return urlOmiUid;
    return localStorage.getItem(STORED_OMI_UID_KEY);
  };

  // Handle username submission and code sending
  const handleUsernameSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const omiUid = getEffectiveUid();
    
    if (!omiUid) {
      setError('Missing Omi identification. Please try again later or contact support.');
      setIsSubmitting(false);
      return;
    }

    if (!username.trim()) {
      setError('Please enter your Loob username');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/send-verification-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          username: username.trim(),
          omiUid
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to send verification code');
      }

      // Check if account is already linked
      if (data.alreadyLinked) {
        setAlreadyLinked(true);
        setLinkSuccess(true);
        return;
      }

      // Check if there's an existing link to another Omi account
      if (data.hasExistingLink) {
        setHasExistingLink(true);
      }

      setEmailSent(true);
      setStep('verification');
    } catch (error) {
      console.error('Error sending verification code:', error);
      setError(error instanceof Error ? error.message : 'Failed to send verification code');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle verification code submission
  const handleVerificationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const omiUid = getEffectiveUid();
    
    if (!omiUid || !username) {
      setError('Missing required information. Please start over.');
      setIsSubmitting(false);
      return;
    }

    if (!verificationCode.trim()) {
      setError('Please enter the verification code from your email');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/verify-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          username: username.trim(),
          code: verificationCode.trim(),
          omiUid
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to verify code');
      }

      // Success! Account linked
      setLinkSuccess(true);
      
      // Remove the uid parameter from the URL to prevent re-sending on refresh
      if (urlOmiUid) {
        // Use replace to avoid adding to history stack
        router.replace('/omi-browser-link');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setError(error instanceof Error ? error.message : 'Failed to verify code');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reset form and go back to username step
  const handleReset = () => {
    setStep('username');
    setEmailSent(false);
    setVerificationCode('');
    setError(null);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-900 to-black p-4">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white mb-2">Link Your Omi Account</h2>
          <p className="text-gray-400">Connect your Omi memories to your Loob account</p>
        </div>

        <div className="bg-gray-800 rounded-lg p-6 shadow-xl">
          {linkSuccess ? (
            <div className="text-center py-6">
              <div className="w-16 h-16 rounded-full bg-gradient-to-r from-pink-500/20 to-orange-500/20 text-pink-400 flex items-center justify-center mx-auto mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-orange-400 mb-4">
                {alreadyLinked ? 'Already Linked!' : 'Account Linked Successfully!'}
              </h3>
              <p className="text-gray-300 mb-2">
                {alreadyLinked 
                  ? 'This Loob account is already connected to your Omi account.' 
                  : 'Your Omi account is now connected to your Loob account.'}
              </p>
              <p className="text-gray-400 text-sm">You can safely close this window.</p>
            </div>
          ) : step === 'username' ? (
            <form onSubmit={handleUsernameSubmit} className="space-y-6">
              <div className="space-y-2">
                <label htmlFor="username" className="block text-sm font-medium text-gray-300">
                  Your Loob Username
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500"
                  placeholder="Enter your Loob username"
                />
              </div>

              {error && (
                <div className="bg-red-900/30 border border-red-800 text-red-300 px-4 py-3 rounded-lg text-sm">
                  {error}
                </div>
              )}

              <div className="text-sm text-gray-400">
                <p>Don't have a Loob account? <a href="https://loob.club/signup" target="_blank" rel="noopener noreferrer" className="text-pink-400 hover:text-pink-300">Create one first</a>.</p>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white ${
                  isSubmitting ? 'bg-gradient-to-r from-pink-600 to-orange-600 opacity-70 cursor-not-allowed' : 'bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500`}
              >
                {isSubmitting ? (
                  <span className="flex items-center">
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Sending...
                  </span>
                ) : (
                  'Send Verification Code'
                )}
              </button>
            </form>
          ) : (
            <div className="space-y-6">
              {emailSent && (
                <div className="text-center py-2 mb-4">
                  <div className="w-12 h-12 rounded-full bg-pink-500/20 text-pink-400 flex items-center justify-center mx-auto mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <p className="text-gray-300 text-sm">We've sent a verification code to your email address.</p>
                  
                  {hasExistingLink && (
                    <div className="mt-2 p-2 bg-yellow-500/10 border border-yellow-500/30 rounded-lg">
                      <p className="text-yellow-300 text-xs">
                        Note: This account is already linked to a different Omi device. 
                        Continuing will replace the existing link.
                      </p>
                    </div>
                  )}
                </div>
              )}

              <form onSubmit={handleVerificationSubmit} className="space-y-6">
                <div className="space-y-2">
                  <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-300">
                    Verification Code
                  </label>
                  <input
                    id="verificationCode"
                    name="verificationCode"
                    type="text"
                    required
                    pattern="[0-9]{4,6}"
                    inputMode="numeric"
                    maxLength={6}
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value.replace(/[^0-9]/g, ''))}
                    className="w-full px-3 py-2 bg-gray-900 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-pink-500 text-center text-xl tracking-widest"
                    placeholder="Enter verification code"
                  />
                </div>

                {error && (
                  <div className="bg-red-900/30 border border-red-800 text-red-300 px-4 py-3 rounded-lg text-sm">
                    {error}
                  </div>
                )}

                <div className="flex flex-col sm:flex-row sm:space-x-3 space-y-3 sm:space-y-0">
                  <button
                    type="button"
                    onClick={handleReset}
                    className="sm:w-1/3 flex justify-center py-2 px-4 border border-gray-600 rounded-lg shadow-sm text-sm font-medium text-gray-300 bg-transparent hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    disabled={isSubmitting}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`sm:w-2/3 flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white ${
                      isSubmitting ? 'bg-gradient-to-r from-pink-600 to-orange-600 opacity-70 cursor-not-allowed' : 'bg-gradient-to-r from-pink-500 to-orange-500 hover:from-pink-600 hover:to-orange-600'
                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500`}
                  >
                    {isSubmitting ? (
                      <span className="flex items-center">
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Verifying...
                      </span>
                    ) : (
                      'Verify Code'
                    )}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}; 