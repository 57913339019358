'use client';

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Companion } from './CompanionManager';
import { useGlobalState } from './GlobalStateContext';
import './CompanionSelection.css';

interface CompanionSelectionProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (companion: Companion) => void;
  initialCompanion?: Companion;
}

const defaultCompanions: Companion[] = [
  {
    id: 'logis',
    name: 'Logis',
    description: 'The Architect\'s Companion - Master of event logistics and resource tracking',
    systemPrompt: 'You are Logis, the Architect\'s Companion, an expert in event logistics, resource tracking, and planning.',
    icon: '🏗️',
    traits: ['Organized', 'Efficient', 'Strategic'],
    level: 1
  },
  {
    id: 'harmoni',
    name: 'Harmoni',
    description: 'The Explorer\'s Companion - Guide for vibe curation and conscious exploration',
    systemPrompt: 'You are Harmoni, the Explorer\'s Companion, specializing in vibe curation, harm reduction, and integration practices.',
    icon: '🌟',
    traits: ['Intuitive', 'Mindful', 'Supportive'],
    level: 1
  },
  {
    id: 'nexus',
    name: 'Nexus',
    description: 'The Alchemist\'s Companion - Catalyst for resource-sharing and connections',
    systemPrompt: 'You are Nexus, the Alchemist\'s Companion, focused on resource-sharing, network building, and community connections.',
    icon: '🔮',
    traits: ['Connected', 'Collaborative', 'Visionary'],
    level: 1
  },
  {
    id: 'ash',
    name: 'Ash',
    description: 'The Burner\'s Companion - Dusty guide for navigating burns and finding community',
    systemPrompt: 'You are Ash, the Burner\'s Companion, specialized in helping users navigate burn events, find camps that match their interests, and integrate the principles of burn culture.',
    icon: '🔥',
    traits: ['Dusty', 'Resourceful', 'Community-minded'],
    level: 1
  }
];

// Update companion stats to use consistent stats across all companions
const companionStats: Record<string, { strengths: Record<string, number> }> = {
  'logis': {
    strengths: {
      Efficiency: 95,
      Vibes: 65,
      Protection: 80,
      Collaboration: 75,
      Personability: 70
    }
  },
  'harmoni': {
    strengths: {
      Efficiency: 70,
      Vibes: 95,
      Protection: 85,
      Collaboration: 75,
      Personability: 90
    }
  },
  'nexus': {
    strengths: {
      Efficiency: 75,
      Vibes: 80,
      Protection: 70,
      Collaboration: 95,
      Personability: 85
    }
  },
  'ash': {
    strengths: {
      Efficiency: 80,
      Vibes: 85,
      Protection: 75,
      Collaboration: 90,
      Personability: 80
    }
  }
};

// Type for companion element strengths (for Pokémon-like type effectiveness)
const companionElements: Record<string, string[]> = {
  'logis': ['Structure', 'Order', 'Precision'],
  'harmoni': ['Flow', 'Spirit', 'Balance'],
  'nexus': ['Community', 'Connection', 'Exchange'],
  'ash': ['Fire', 'Dust', 'Adventure']
};

export const CompanionSelection: React.FC<CompanionSelectionProps> = ({ 
  isOpen, 
  onClose, 
  onSelect,
  initialCompanion
}) => {
  const globalState = useGlobalState();
  const { userId, isAnonymous, activeCompanion } = globalState;
  const [anonymousActiveCompanion, setAnonymousActiveCompanion] = useState<Companion | null>(null);
  
  // Use different active companion sources based on user authentication state
  // For anonymous users, use the local state loaded from localStorage
  // For logged-in users, use the activeCompanion from globalState
  const effectiveActiveCompanion = isAnonymous ? anonymousActiveCompanion : activeCompanion;
  
  // Load anonymous active companion from localStorage on first render
  React.useEffect(() => {
    if (isAnonymous) {
      try {
        const anonymousState = localStorage.getItem('anonymousUserState');
        if (anonymousState) {
          const parsedState = JSON.parse(anonymousState);
          if (parsedState.activeCompanion) {
            setAnonymousActiveCompanion(parsedState.activeCompanion);
          }
        }
      } catch (error) {
        console.error('Error loading anonymous companion state:', error);
      }
    }
  }, [isAnonymous]);

  const { setUserState } = useGlobalState();
  const [selectedCompanion, setSelectedCompanion] = useState<Companion | null>(null);
  const [phase, setPhase] = useState<'intro' | 'selection' | 'ritual' | 'completion'>('intro');
  const [dialogText, setDialogText] = useState('Welcome, traveler! To begin your journey with Loob, you must choose a companion who will guide and assist you. Each has unique powers and abilities that resonate differently with each person.');
  const [isTyping, setIsTyping] = useState(true);
  const [typedText, setTypedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ritualProgress, setRitualProgress] = useState(0);
  const [tiltDirection, setTiltDirection] = useState({ x: 0, y: 0 });
  const cardRef = useRef<HTMLDivElement>(null);
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [previousCompanion, setPreviousCompanion] = useState<Companion | null>(null);
  const [showJourneyTransition, setShowJourneyTransition] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  
  // Detect mobile devices
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSelectedCompanion(null);
      setPhase('intro');
      setCurrentIndex(0);
      setRitualProgress(0);
    }
  }, [isOpen]);

  useEffect(() => {
    const initialDialog = getInitialGreeting();
    
    let currentText = '';
    let index = 0;
    const timeoutId = setInterval(() => {
      if (index < initialDialog.length) {
        currentText += initialDialog.charAt(index);
        setDialogText(currentText);
        index++;
      } else {
        clearInterval(timeoutId);
      }
    }, 30);

    return () => clearInterval(timeoutId);
  }, [previousCompanion, isReturningUser]);

  // Optimized typing effect
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    const typeText = async () => {
      setIsTyping(true);
      setTypedText('');
      
      // For mobile devices, set the text immediately to avoid layout jitter
      if (isMobile) {
        setTypedText(dialogText);
        // Small delay to show typing effect visually
        timeoutId = setTimeout(() => {
          setIsTyping(false);
        }, 800);
      } else {
        // For desktop, use character-by-character typing
        for (let i = 0; i <= dialogText.length; i++) {
          await new Promise(resolve => {
            timeoutId = setTimeout(resolve, 20);
          });
          setTypedText(dialogText.slice(0, i));
        }
        setIsTyping(false);
      }
    };

    typeText();

    return () => clearTimeout(timeoutId);
  }, [dialogText, isMobile]);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('loob_companion_selected');
    const userStateStr = localStorage.getItem('userState');
    
    if (hasVisitedBefore) {
      setIsReturningUser(true);
      
      // Try to get the previously selected companion
      if (userStateStr) {
        try {
          const userState = JSON.parse(userStateStr);
          if (userState.activeCompanion) {
            const activeCompanionId = userState.activeCompanion.id;
            const foundCompanion = defaultCompanions.find(c => c.id === activeCompanionId);
            if (foundCompanion) {
              setPreviousCompanion(foundCompanion);
              
              // Pre-select the active companion if initialCompanion is not provided
              if (!initialCompanion) {
                setSelectedCompanion(foundCompanion);
                setCurrentIndex(defaultCompanions.findIndex(c => c.id === activeCompanionId));
              }
            }
          }
        } catch (error) {
          console.error('Error parsing user state:', error);
        }
      }
    }
  }, [initialCompanion]);

  // Add useEffect to prevent body scrolling when modal is open and ensure viewport is set correctly
  useEffect(() => {
    if (isOpen) {
      // Prevent body scrolling when modal is open
      document.body.style.overflow = 'hidden';
      
      // Fix viewport settings for mobile devices
      const viewportMeta = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.setAttribute('content', 
          'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover');
      } else {
        const meta = document.createElement('meta');
        meta.name = 'viewport';
        meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover';
        document.head.appendChild(meta);
      }
      
      // Add touch-specific styles for mobile
      document.documentElement.classList.add('companion-selection-open');
      document.body.classList.add('companion-selection-open');

      // Prevent pull-to-refresh on iOS
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
    }
    
    return () => {
      // Restore scrolling when modal closes
      const scrollY = document.body.style.top;
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      
      // Restore scroll position
      if (scrollY) {
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
      
      document.documentElement.classList.remove('companion-selection-open');
      document.body.classList.remove('companion-selection-open');
    };
  }, [isOpen]);

  const handleCardTilt = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!cardRef.current) return;
    
    const rect = cardRef.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / rect.width - 0.5;
    const y = (e.clientY - rect.top) / rect.height - 0.5;
    
    setTiltDirection({ x, y });
  };

  const resetCardTilt = () => {
    setTiltDirection({ x: 0, y: 0 });
  };

  const handleCompanionSelect = (companion: Companion) => {
    setSelectedCompanion(companion);
    
    // Customize dialog based on whether this is the user's previous companion
    if (previousCompanion && companion.id === previousCompanion.id) {
      setDialogText(`${companion.name} is delighted to see you again! The connection between you remains strong. Would you like to continue your journey with ${companion.name}?`);
    } else {
      setDialogText(`${companion.name} seems drawn to you! Their energy resonates with yours. Would you like to form a bond with ${companion.name}?`);
    }
  };

  const handleBeginRitual = () => {
    if (!selectedCompanion) return;
    
    setPhase('ritual');
    setRitualProgress(0);
    
    // Check if this is the same companion they had before
    const isReconnecting = previousCompanion && selectedCompanion.id === previousCompanion.id;
    
    // Set different dialog text based on whether they're reconnecting
    if (isReconnecting) {
      setDialogText(`Renewing your connection with ${selectedCompanion.name}...`);
    } else {
      setDialogText(`Focus your energy as we summon ${selectedCompanion.name} to your side...`);
    }
    
    // Start the ritual animation sequence with customized steps
    const ritualSteps = isReconnecting ? [
      `${selectedCompanion.name} recognizes your familiar energy...`,
      `Your previous connection with ${selectedCompanion.name} strengthens...`,
      `${selectedCompanion.name} is delighted to reunite with you...`,
      `Your partnership with ${selectedCompanion.name} is renewed and strengthened!`
    ] : [
      `The environment shifts as ${selectedCompanion.name}'s energy approaches...`,
      `${selectedCompanion.name}'s presence grows stronger...`,
      `The connection between you and ${selectedCompanion.name} forms...`,
      `${selectedCompanion.name} has accepted your call! They are now your companion.`
    ];
    
    let step = 0;
    const ritualInterval = setInterval(() => {
      setRitualProgress((prev) => prev + 25);
      setDialogText(ritualSteps[step]);
      step++;
      
      if (step >= ritualSteps.length) {
        clearInterval(ritualInterval);
        setTimeout(() => {
          setPhase('completion');
          
          // Set different completion message based on whether they're reconnecting
          if (isReconnecting) {
            setDialogText(`${selectedCompanion.name} is once again by your side! Your renewed connection has made your bond even stronger. What adventures await you both?`);
          } else {
            setDialogText(`${selectedCompanion.name} is now your companion! They will help you navigate and make the most of your journey. What awaits you both?`);
          }
        }, 2000);
      }
    }, 2500);
    
    return () => clearInterval(ritualInterval);
  };

  const handleConfirm = async () => {
    if (!selectedCompanion) {
      console.log('CompanionSelection: No companion selected, cannot confirm');
      return;
    }
    
    console.log(`CompanionSelection: Confirming companion selection for ${selectedCompanion.name}`);
    
    try {
      // Get current state to preserve existing discovered companions
      const existingState = localStorage.getItem('userState');
      const parsedState = existingState ? JSON.parse(existingState) : {};
      const existingDiscovered = parsedState.discoveredCompanions || [];

      // Create new state with merged discovered companions
      const newState = {
        activeCompanion: selectedCompanion,
        hasChosenCompanion: true,
        discoveredCompanions: [...new Set([...existingDiscovered, selectedCompanion.id])]
      };

      // Update local state first
      console.log(`CompanionSelection: Updating global state with companion ${selectedCompanion.name}`);
      setUserState({
        ...newState,
        activeCompanion: selectedCompanion.id // Store the ID instead of the object
      });
      
      // Update localStorage to ensure persistence
      if (existingState) {
        const updatedState = {
          ...parsedState,
          ...newState,
          isAnonymous: parsedState.isAnonymous // Preserve the anonymity status
        };
        localStorage.setItem('userState', JSON.stringify(updatedState));
        console.log('CompanionSelection: Updated localStorage with companion selection:', selectedCompanion.name);

        // If user is logged in (not anonymous), persist to database
        if (userId && !isAnonymous) {
          try {
            console.log(`CompanionSelection: Persisting companion selection to database for user ${userId}`);
            const response = await fetch(`/api/users/${userId}/companions`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(newState)
            });

            if (!response.ok) {
              const errorText = await response.text().catch(() => '');
              console.error(
                'CompanionSelection: Failed to persist companion data to database:', 
                errorText ? `Error: ${errorText}` : `Status: ${response.status}`
              );
            } else {
              console.log('CompanionSelection: Successfully persisted companion selection to database');
            }
          } catch (error) {
            console.error('CompanionSelection: Error persisting companion data:', error);
          }
        } else {
          console.log('CompanionSelection: User is anonymous or missing userId, not persisting to database');
          // For anonymous users, store in anonymousUserState
          if (isAnonymous) {
            try {
              localStorage.setItem('anonymousUserState', JSON.stringify({
                activeCompanion: selectedCompanion,
                hasChosenCompanion: true
              }));
            } catch (e) {
              console.error('CompanionSelection: Error saving anonymous companion state:', e);
            }
          }
        }
      }
      
      // Call the onSelect callback 
      console.log(`CompanionSelection: Calling onSelect with companion ${selectedCompanion.name}`);
      await onSelect(selectedCompanion);
      onClose();
    } catch (error) {
      console.error('CompanionSelection: Error in handleConfirm:', error);
    }
  };

  const handleContinue = () => {
    if (phase === 'intro') {
      setPhase('selection');
      setDialogText('Four unique companions await your choice. Each has special powers and abilities. Look closely, and choose the one that calls to you...');
    }
  };

  // Modify to directly set currentIndex rather than incrementing/decrementing
  const handleSelectIndex = (index: number) => {
    setCurrentIndex(index);
    setSelectedCompanion(null);
  };

  // Modify renderCompanionStats to be more responsive
  const renderCompanionStats = (companion: Companion) => {
    const stats = companionStats[companion.id]?.strengths || {};
    
    return (
      <motion.div 
        className="companion-stats"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
      >
        <div className="stat-bars">
          {Object.entries(stats).map(([statName, value]) => (
            <div key={statName} className="stat-bar-container">
              <div className="stat-name">{statName}</div>
              <div className="stat-bar-bg">
                <motion.div 
                  className="stat-bar-fill"
                  initial={{ width: 0 }}
                  animate={{ width: `${value}%` }}
                  transition={{ duration: 1, delay: 0.2 }}
                />
              </div>
              <div className="stat-value">{value}</div>
            </div>
          ))}
        </div>
      </motion.div>
    );
  };

  const getInitialGreeting = () => {
    if (isReturningUser) {
      if (previousCompanion) {
        return `Welcome back! It's good to see you again. ${previousCompanion.name} has been waiting for your return. Would you like to continue your journey with ${previousCompanion.name} or choose a different companion today?`;
      }
      return "Welcome back! It's good to see you again. Your companion awaits your return. Which one will guide your journey today?";
    } else {
      return "Welcome, explorer! I'm here to guide you on your journey. First, you'll need to choose a companion to join you on your adventure. Each has unique traits that complement different exploration styles.";
    }
  };

  const handleJourneyStart = () => {
    if (!selectedCompanion) {
      console.log('CompanionSelection: No companion selected, cannot start journey');
      return;
    }

    console.log(`CompanionSelection: Starting journey with companion ${selectedCompanion.name}`);
    
    // Show the journey transition
    setShowJourneyTransition(true);
    
    // Save that user has selected a companion before
    localStorage.setItem('loob_companion_selected', 'true');
    
    // Create a helper function to handle the selection
    const completeSelection = async () => {
      try {
        // For anonymous users, store in anonymousUserState
        if (isAnonymous) {
          console.log(`CompanionSelection: Storing anonymous companion selection for ${selectedCompanion.name}`);
          localStorage.setItem('anonymousUserState', JSON.stringify({
            activeCompanion: selectedCompanion,
            hasChosenCompanion: true
          }));
        }
        
        // Get current state to properly merge data
        const userStateStr = localStorage.getItem('userState');
        const userStateObj = userStateStr ? JSON.parse(userStateStr) : {};
        
        // Update localStorage with the selection
        const newState = {
          ...userStateObj,
          activeCompanion: selectedCompanion,
          hasChosenCompanion: true,
          discoveredCompanions: [...new Set([
            ...(userStateObj.discoveredCompanions || []), 
            selectedCompanion.id
          ])]
        };
        
        localStorage.setItem('userState', JSON.stringify(newState));
        
        console.log(`CompanionSelection: Calling onSelect with companion ${selectedCompanion.name}`);
        // Call the onSelect callback with the selected companion
        await onSelect(selectedCompanion);
      } catch (error) {
        console.error('CompanionSelection: Error completing companion selection:', error);
      } finally {
        // Always close the dialog after selection
        onClose();
      }
    };
    
    // After animation delay, complete the process
    setTimeout(() => {
      completeSelection();
    }, 3000); // 3 second delay for the animation
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="companion-selection-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="companion-selection-container">
        {/* Animated background elements */}
        <div className="particle-container">
          {[...Array(isMobile ? 10 : 20)].map((_, i) => (
            <div 
              key={i} 
              className="floating-particle"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDuration: `${Math.random() * 20 + 10}s`,
                animationDelay: `${Math.random() * 10}s`
              }}
            />
          ))}
        </div>
        
        <div className="companion-selection-content">
          {phase !== 'selection' && (
            <div className="professor-section">
              <motion.div 
                className="professor-avatar"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, type: "spring" }}
              >
                {phase === 'ritual' ? '✨' : phase === 'completion' ? '🎉' : '🧙‍♂️'}
              </motion.div>
              
              <motion.div 
                className="dialog-box"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <p className={`dialog-text ${isTyping ? 'typing' : ''}`}>
                  {typedText}
                  {isTyping && isMobile ? null : isTyping ? <span className="typing-cursor">|</span> : null}
                </p>
              </motion.div>
            </div>
          )}

          <AnimatePresence mode="wait">
            {phase === 'intro' && (
              <motion.button
                onClick={handleContinue}
                className="btn-primary"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.98 }}
                style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
              >
                Begin Your Journey
              </motion.button>
            )}

            {phase === 'selection' && (
              <motion.div 
                className="companion-carousel"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {/* Compact dialog directly in the carousel */}
                <motion.div 
                  className="compact-dialog"
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {/* Commented out narrative text to fit better on mobile */}
                  {/* <p className={`dialog-text-compact ${isTyping ? 'typing' : ''}`}>
                    {typedText}
                    {isTyping && isMobile ? null : isTyping ? <span className="typing-cursor">|</span> : null}
                  </p> */}
                </motion.div>
                
                <div className="carousel-container">
                  {defaultCompanions.map((companion, index) => (
                    <motion.div
                      key={companion.id}
                      className={`companion-card pokemon-card ${selectedCompanion?.id === companion.id ? 'selected' : ''} ${index === currentIndex ? 'active' : ''}`}
                      style={{
                        display: index === currentIndex ? 'flex' : 'none',
                        transform: `perspective(1000px) rotateY(${tiltDirection.x * 10}deg) rotateX(${-tiltDirection.y * 10}deg)`
                      }}
                      onClick={() => handleCompanionSelect(companion)}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      onMouseMove={handleCardTilt}
                      onMouseLeave={resetCardTilt}
                      ref={cardRef}
                    >
                      <div className="card-shine" style={{
                        background: `radial-gradient(circle at ${50 + tiltDirection.x * 50}% ${50 + tiltDirection.y * 50}%, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 60%)`
                      }}></div>
                      
                      <div className="card-header">
                        <h3 className="companion-name">{companion.name}</h3>
                        <div className="companion-level">Lvl {companion.level}</div>
                      </div>
                      
                      <div className="companion-icon-container">
                        <div className="companion-icon">{companion.icon}</div>
                        <div className="icon-glow"></div>
                      </div>
                      
                      <p className="companion-description">{companion.description}</p>
                      
                      <div className="companion-traits">
                        {(companion.traits ?? []).map((trait, idx) => (
                          <motion.span 
                            key={`${trait}-${idx}`} 
                            className="trait-tag"
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: idx * 0.1 }}
                          >
                            {trait}
                          </motion.span>
                        ))}
                      </div>
                      
                      {/* Always show stats */}
                      {renderCompanionStats(companion)}
                      
                      {selectedCompanion?.id === companion.id && (
                        <div className="selection-indicator">
                          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#FFC4BC" strokeWidth="2"/>
                            <path d="M7.5 12L10.5 15L16.5 9" stroke="#FFC4BC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                        </div>
                      )}
                      
                      {typeof effectiveActiveCompanion !== 'string' && 
                       effectiveActiveCompanion?.id === companion.id && (
                        <div className="active-indicator">
                          Active
                        </div>
                      )}
                    </motion.div>
                  ))}
                </div>
                
                {/* Companion thumbnails at the bottom */}
                <div className="companion-thumbnails">
                  {defaultCompanions.map((companion, index) => (
                    <button
                      key={index}
                      className={`companion-thumbnail ${index === currentIndex ? 'active' : ''}`}
                      onClick={() => handleSelectIndex(index)}
                      aria-label={`Select ${companion.name}`}
                    >
                      <div className="thumbnail-icon">{companion.icon}</div>
                      <div className="thumbnail-name">{companion.name}</div>
                    </button>
                  ))}
                </div>
              </motion.div>
            )}
            
            {phase === 'ritual' && (
              <motion.div 
                className="ritual-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="ritual-progress-container">
                  <div className="ritual-progress-bar">
                    <motion.div 
                      className="ritual-progress-fill"
                      initial={{ width: "0%" }}
                      animate={{ width: `${ritualProgress}%` }}
                      transition={{ duration: 0.8 }}
                    />
                  </div>
                  <div className="ritual-percentage">{ritualProgress}%</div>
                </div>
                
                <div className="ritual-animation">
                  <motion.div 
                    className="ritual-companion-icon"
                    animate={{ 
                      scale: [1, 1.2, 1],
                      rotate: [0, 5, -5, 0],
                      opacity: [0.5, 0.8, 1]
                    }}
                    transition={{ 
                      duration: 3,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}
                  >
                    {selectedCompanion?.icon}
                  </motion.div>
                  
                  <div className="ritual-particles">
                    {[...Array(20)].map((_, i) => (
                      <motion.div 
                        key={i}
                        className="ritual-particle"
                        initial={{ 
                          x: 0, 
                          y: 0, 
                          opacity: 0,
                          backgroundColor: `hsl(${Math.random() * 60 + 330}, 80%, 70%)`
                        }}
                        animate={{ 
                          x: (Math.random() - 0.5) * 200,
                          y: (Math.random() - 0.5) * 200,
                          opacity: [0, 1, 0],
                          scale: [0, 1, 0]
                        }}
                        transition={{ 
                          duration: 2 + Math.random() * 2,
                          repeat: Infinity,
                          delay: Math.random() * 2
                        }}
                      />
                    ))}
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <AnimatePresence>
          {selectedCompanion && phase === 'selection' && !isTyping && (
            <motion.button
              onClick={handleBeginRitual}
              className="btn-accent"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              <span>Begin Summoning</span>
              <span>✨</span>
            </motion.button>
          )}

          {phase === 'completion' && !isTyping && (
            <motion.button
              onClick={handleJourneyStart}
              className="btn-primary"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              <span>Begin Your Journey</span>
              <span>🚀</span>
            </motion.button>
          )}
        </AnimatePresence>
      </div>

      {/* Add the journey transition */}
      <div className={`journey-transition ${showJourneyTransition ? 'active' : ''}`}>
        <div className="journey-animation">
          <div className="journey-glow"></div>
          <div className="journey-companion">
            {selectedCompanion ? selectedCompanion.icon : '✨'}
          </div>
          <div className="journey-particles">
            {/* Generate 20 particles with random positions */}
            {[...Array(20)].map((_, i) => {
              const randomX = (Math.random() * 200 - 100) + 'px';
              const randomY = (Math.random() * 200 - 100) + 'px';
              return (
                <div 
                  key={i} 
                  className="journey-particle" 
                  style={{ 
                    '--x': randomX, 
                    '--y': randomY,
                    left: Math.random() * 100 + '%',
                    top: Math.random() * 100 + '%',
                    animationDuration: 5 + Math.random() * 10 + 's'
                  } as React.CSSProperties}
                ></div>
              );
            })}
          </div>
        </div>
        
        <div className="journey-text">
          {isReturningUser && previousCompanion && selectedCompanion?.id === previousCompanion.id
            ? `Continuing Your Journey with ${selectedCompanion.name}...`
            : isReturningUser
              ? `Starting a New Chapter with ${selectedCompanion?.name}...`
              : 'Beginning Your Journey...'}
        </div>
        
        <div className="journey-loading">
          <div className="journey-dot"></div>
          <div className="journey-dot"></div>
          <div className="journey-dot"></div>
        </div>
      </div>
    </motion.div>
  );
};

