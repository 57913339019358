import { useState, useEffect, useCallback, useRef } from 'react';
import { useGlobalState } from '../components/GlobalStateContext';
import { useRouter } from 'next/navigation';
import { UserSession } from '@/lib/auth';

// Singleton to guarantee global state consistency
class AuthStateManager {
  private static instance: AuthStateManager;
  private _initialized = false;
  private _initializing = false;
  private _authListeners: Function[] = [];

  // Private constructor for singleton
  private constructor() {}

  public static getInstance(): AuthStateManager {
    if (!AuthStateManager.instance) {
      AuthStateManager.instance = new AuthStateManager();
    }
    return AuthStateManager.instance;
  }

  // State getters
  get initialized() { return this._initialized; }
  get initializing() { return this._initializing; }
  
  // State setters with notification
  set initialized(value: boolean) {
    this._initialized = value;
    this.notifyListeners();
  }
  
  set initializing(value: boolean) {
    this._initializing = value;
    this.notifyListeners();
  }
  
  // Add listener for state changes
  public addListener(callback: Function) {
    this._authListeners.push(callback);
    return () => {
      this._authListeners = this._authListeners.filter(cb => cb !== callback);
    };
  }
  
  // Notify all listeners of state change
  private notifyListeners() {
    this._authListeners.forEach(callback => {
      try {
        callback();
      } catch (e) {
        console.error('Error in auth state listener:', e);
      }
    });
  }
}

// Get the singleton instance
const authManager = AuthStateManager.getInstance();

// Hook instance counter for debug
let hookInstanceCount = 0;

export type AuthUser = {
  userId: string;
  pseudonym: string;
  email: string | null;
  phone: string | null;
  isAnonymous: boolean;
  hasChosenCompanion: boolean;
  activeCompanion: any | null;
  discoveredCompanions: any[];
  connectedLoobricates: any[];
};

/**
 * Custom hook for auth state management with stable state transitions
 */
export const useAuth = () => {
  const instanceId = useRef(`auth-hook-${++hookInstanceCount}`);
  const isDev = process.env.NODE_ENV === 'development';
  
  console.log(`[useAuth ${instanceId.current}] Hook initialized`, isDev ? '(development mode)' : '');
  
  const { userState, setUserState, setSessionId } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [authReady, setAuthReady] = useState(authManager.initialized);
  const router = useRouter();
  
  const hasInitializedRef = useRef(false);
  const initializationPromiseRef = useRef<Promise<void> | null>(null);

  /**
   * Generate a consistent session ID
   */
  const generateSessionId = useCallback(() => {
    return `session-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  }, []);

  // Add development-specific debug logging
  useEffect(() => {
    if (isDev) {
      console.log(`[useAuth ${instanceId.current}] Current state:`, {
        isAuthenticated: !!userState && !userState.isAnonymous,
        isAnonymous: !!userState && userState.isAnonymous,
        authReady,
        userId: userState?.userId,
        hasActiveCompanion: !!userState?.activeCompanion
      });
    }
  }, [isDev, userState, authReady, instanceId]);

  /**
   * Initialize auth state
   */
  const initializeAuth = useCallback(async () => {
    if (initializationPromiseRef.current) {
      return initializationPromiseRef.current;
    }

    initializationPromiseRef.current = (async () => {
      try {
        // Skip server-side
        if (typeof window === 'undefined') {
          if (isDev) {
            console.log(`[useAuth ${instanceId.current}] Server-side, skipping initialization`);
          }
          return;
        }
        
        // Fetch current session from API
        try {
          const response = await fetch('/api/auth/session');
          if (response.ok) {
            const data = await response.json();
            if (data.user) {
              if (isDev) {
                console.log(`[useAuth ${instanceId.current}] Setting user state from session:`, {
                  userId: data.user.userId,
                  isAnonymous: data.user.isAnonymous,
                  hasActiveCompanion: !!data.user.activeCompanion
                });
              }
              
              // Generate a new session for this browser instance
              const newSessionId = generateSessionId();
              
              // Update global state
              setUserState(data.user);
              setSessionId(newSessionId);
            }
          }
        } catch (e) {
          console.error(`[useAuth ${instanceId.current}] Error fetching session:`, e);
        }
      } catch (e) {
        console.error(`[useAuth ${instanceId.current}] Error during auth initialization:`, e);
      } finally {
        if (isDev) {
          console.log(`[useAuth ${instanceId.current}] Auth initialization complete`);
        }
        authManager.initializing = false;
        authManager.initialized = true;
        setAuthReady(true);
      }
    })();

    return initializationPromiseRef.current;
  }, [setUserState, setSessionId, generateSessionId, instanceId, isDev]);

  // Initialize auth state on mount
  useEffect(() => {
    if (!hasInitializedRef.current) {
      hasInitializedRef.current = true;
      initializeAuth();
    }
  }, [initializeAuth]);

  /**
   * Force refresh auth state
   */
  const refreshAuthState = useCallback(async () => {
    console.log(`[useAuth ${instanceId.current}] Manually refreshing auth state`);
    
    try {
      const response = await fetch('/api/auth/session');
      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          if (isDev) {
            console.log(`[useAuth ${instanceId.current}] Setting user state from session during refresh`);
          }
          
          // Update global state
          setUserState(data.user);
          
          // Update session ID
          const newSessionId = generateSessionId();
          setSessionId(newSessionId);
          
          return true;
        }
      }
      return false;
    } catch (e) {
      console.error(`[useAuth ${instanceId.current}] Error during refresh:`, e);
      return false;
    }
  }, [setUserState, setSessionId, generateSessionId, instanceId, isDev]);

  /**
   * Login with credentials
   */
  const login = useCallback(async (credentials: string | { pseudonym: string; password: string; isAnonymous?: boolean }) => {
    console.log(`[useAuth ${instanceId.current}] Login attempt`);
    setError(null);
    setIsLoading(true);
    
    try {
      // Handle both string and object-based login
      const pseudonym = typeof credentials === 'string' ? credentials : credentials.pseudonym;
      const password = typeof credentials === 'string' ? '' : credentials.password;
      const isAnonymous = typeof credentials === 'string' ? false : credentials.isAnonymous || false;

      // API call to verify credentials
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          pseudonym: pseudonym.trim(), 
          password,
          isAnonymous 
        }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        const errorMsg = data.error || "Login failed";
        console.error(`[useAuth ${instanceId.current}] Login error:`, errorMsg);
        setError(errorMsg);
        setIsLoading(false);
        return { success: false, error: errorMsg };
      }

      if (isDev) {
        console.log(`[useAuth ${instanceId.current}] Login successful:`, {
          userId: data.user?.userId || 'unknown',
          isAnonymous,
          hasActiveCompanion: !!data.user?.activeCompanion
        });
      }

      // Update global state with user data
      if (data.user) {
        setUserState(data.user);
        const newSessionId = generateSessionId();
        setSessionId(newSessionId);
      }

      setIsLoading(false);
      return { success: true, user: data.user };
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : "Login failed";
      console.error(`[useAuth ${instanceId.current}] Login error:`, errorMsg);
      setError(errorMsg);
      setIsLoading(false);
      return { success: false, error: errorMsg };
    }
  }, [instanceId, setUserState, setSessionId, generateSessionId, isDev]);

  /**
   * Log out the current user
   */
  const logout = useCallback(async () => {
    console.log(`[useAuth ${instanceId.current}] Logging out`);
    
    try {
      // Call logout API endpoint
      await fetch('/api/auth/logout', { method: 'POST' });
      
      // Clear state
      setUserState(null);
      setSessionId(null);
      
      // Redirect to home page
      router.push('/');
      
      console.log(`[useAuth ${instanceId.current}] Logout complete`);
    } catch (e) {
      console.error(`[useAuth ${instanceId.current}] Error during logout:`, e);
    }
  }, [setUserState, setSessionId, router, instanceId]);

  // Add companion state sync effect
  useEffect(() => {
    if (!userState?.userId || userState.isAnonymous) return;

    const syncCompanionState = async () => {
      try {
        const response = await fetch(`/api/users/${userState.userId}/data`);
        if (!response.ok) throw new Error('Failed to fetch user data');
        
        const data = await response.json();
        if (data.user) {
          // Only update if companion state has changed
          const currentCompanion = userState.activeCompanion;
          const newCompanion = data.user.activeCompanion;
          
          if (JSON.stringify(currentCompanion) !== JSON.stringify(newCompanion)) {
            console.log(`[useAuth ${instanceId.current}] Updating companion state:`, {
              from: currentCompanion?.name,
              to: newCompanion?.name
            });
            
            setUserState(prev => ({
              ...prev,
              activeCompanion: newCompanion,
              hasChosenCompanion: data.user.hasChosenCompanion ?? prev.hasChosenCompanion,
              discoveredCompanions: data.user.discoveredCompanions || prev.discoveredCompanions
            }));
          }
        }
      } catch (error) {
        console.error(`[useAuth ${instanceId.current}] Error syncing companion state:`, error);
      }
    };

    // Initial sync
    syncCompanionState();

    // Set up periodic sync every 30 seconds
    const syncInterval = setInterval(syncCompanionState, 30000);

    return () => clearInterval(syncInterval);
  }, [userState?.userId, userState?.isAnonymous, userState?.activeCompanion, setUserState, instanceId]);

  return {
    user: userState,
    isAuthenticated: !!userState && !userState.isAnonymous,
    isAnonymous: !!userState && userState.isAnonymous,
    isLoading,
    error,
    authReady,
    login,
    logout,
    refreshAuthState
  };
};