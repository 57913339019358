"use client";

import React, { useEffect, useState, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useGlobalState } from "./GlobalStateContext";
import { useAuth } from "../hooks/useAuth";
import { CompanionSelection } from './CompanionSelection';
import "./SplashScreen.css";
import Image from 'next/image';
import LoobrarySignUp from './SignUp';

// Import or define the Companion type to match what CompanionSelection expects
type Companion = {
  id: string;
  name: string;
  description: string;
  systemPrompt: string;
  icon: string;
  traits: string[];
  level: number;
};

export const SignUpScreen: React.FC<{ 
  onClose?: () => void, 
  onComplete?: () => void 
}> = ({ onClose, onComplete }) => {
  const { setSessionId } = useGlobalState();
  const { updateCompanion } = useAuth();
  
  // State management
  const [mounted, setMounted] = useState(false);
  const [phase, setPhase] = useState<"signupPhase" | "companionPhase">("signupPhase");
  const [selectedCompanion, setSelectedCompanion] = useState<Companion | null>(null);

  // Initial setup - only run once
  useEffect(() => {
    setMounted(true);
  }, []); // Empty dependency array ensures this only runs once

  const handleSignUpComplete = useCallback(() => {
    // After signup, move to companion selection
    setPhase("companionPhase");
  }, []);

  const handleCompanionSelected = useCallback((companion: Companion) => {
    setSelectedCompanion(companion);
    
    // Update companion in auth state
    updateCompanion(companion);
    
    // Complete flow
    if (onComplete) {
      onComplete();
    }
  }, [updateCompanion, onComplete]);

  // Early return if not mounted
  if (!mounted) {
    return null;
  }

  const headerContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
    gap: '0.5rem'
  };

  return (
    <motion.div 
      className="splashScreen"
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      data-testid="motion-signupScreen"
    >
      <div className="gridBackground" />
      
      <div className="splashScreenContent">
        {phase === "signupPhase" && (
          <div style={headerContainerStyle}>
            <Image 
              src="/LoobLogo.png"
              alt="Loob Logo"
              width={70}
              height={70}
              priority={true}
            />
            <h1 className="splashTitle" style={{ margin: 0 }}>Loob</h1>
          </div>
        )}

        {/* Developer Tools Section - Positioned at top right for desktop only */}
        <div className="devToolsCorner">
          <div className="devToolsLinks">
            <a 
              href="/rag-evaluation" 
              target="_blank" 
              rel="noopener noreferrer"
              className="devToolLink"
            >
              RAG Evaluation Tool
            </a>
            <a 
              href="/api/rag-diagnostic?query=camps+with+yoga" 
              target="_blank" 
              rel="noopener noreferrer"
              className="devToolLink"
            >
              RAG Diagnostic
            </a>
          </div>
        </div>

        <AnimatePresence mode="wait">
          {phase === "signupPhase" && (
            <motion.div
              key="signup-form"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <LoobrarySignUp
                onBack={() => onClose?.()}
                onExplore={handleSignUpComplete}
              />
            </motion.div>
          )}

          {phase === "companionPhase" && (
            <motion.div
              key="companion-selection"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3 }}
            >
              <CompanionSelection
                isOpen={true}
                onClose={() => onClose?.()}
                onSelect={handleCompanionSelected}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}; 