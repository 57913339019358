import React, { createContext, useContext, useState } from 'react';
import type { Offering } from './OfferingProfile';
import type { Loobricate } from './GlobalStateContext';

interface ModalContextType {
  activeOffering: Offering | null;
  setActiveOffering: (offering: Offering | null) => void;
  isOfferingModalOpen: boolean;
  setIsOfferingModalOpen: (isOpen: boolean) => void;
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeOffering, setActiveOffering] = useState<Offering | null>(null);
  const [isOfferingModalOpen, setIsOfferingModalOpen] = useState(false);

  return (
    <ModalContext.Provider value={{
      activeOffering,
      setActiveOffering,
      isOfferingModalOpen,
      setIsOfferingModalOpen
    }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}; 